import { TuiPreview } from '@taiga-ui/kit';
import { inject, isDevMode, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import {
  BrowserAnimationsModule,
  provideAnimations,
} from '@angular/platform-browser/animations';

import {
  TUI_DEFAULT_NUMBER_FORMAT,
  TUI_NOTIFICATION_DEFAULT_OPTIONS,
  TUI_NOTIFICATION_OPTIONS,
  TUI_NUMBER_FORMAT,
  TuiAlert,
  TuiDialog,
  tuiIconResolverProvider,
  TuiLink,
  TuiRoot,
} from '@taiga-ui/core';
import {
  MetaReducer,
  State,
  StoreModule,
  USER_PROVIDED_META_REDUCERS,
} from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CookieService } from 'ngx-cookie-service';
import { LetDirective } from '@ngrx/component';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';

import { ENVIRONMENT } from '@chassis/shared/environment';
import { environment } from '../environments/environment';
import { NG_EVENT_PLUGINS } from '@taiga-ui/event-plugins';

import { AuthSharedActionTypes, AuthSharedModule } from '@chassis/auth/shared';

import { SharedNotificationsModule } from '@chassis/shared/notifications';
import { ElectronService } from '@chassis/shared/services/electron';
import { SharedServicesQueryParamsModule } from '@chassis/shared/services/query-params';

import { AppComponent } from './app.component';
import { APP_BASE_HREF } from '@angular/common';
import { IpcFactory, IpcService } from '@chassis/shared/services/ipc';
import { LaunchDarklyService } from '@chassis/shared/services/launch-darkly';
import LogRocket from 'logrocket';
import createNgrxMiddleware from 'logrocket-ngrx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireModule } from '@angular/fire/compat';
import { UsersService } from '@chassis/shared/services/users';
import { AboutModule } from '@chassis/about';
import { ShortcutsUiService } from '@chassis/shared/services/shortcuts';
import { PushNotificationsModule } from '@chassis/push-notifications';
import { YardManagementModule } from '@chassis/yard-management';

import { ConnectFrontendModule } from '@chassis/desktop/connect/frontend';
import { appRoutes } from './app.routes';
import { SoftwareRegistrationModule } from '@chassis/software-registration';
import {
  NgxGoogleAnalyticsModule,
  provideGoogleAnalytics,
  provideGoogleAnalyticsRouter,
} from '@hakimio/ngx-google-analytics';
import { LogrocketService } from '@chassis/shared/services/logrocket';
import { VendorPoliciesModule } from '@chassis/vendor-policies';
import { CartSharedModule } from '@chassis/cart/shared';
import { AppStateModule } from '@chassis/app-state';
import { TUI_LANGUAGE } from '@taiga-ui/i18n';
import { map } from 'rxjs';
import { BaseModule } from '@chassis/shared/module';

const logrocketMiddleware = createNgrxMiddleware(LogRocket);

function clearState(reducer: any) {
  return function (state: any, action: any) {
    if (action.type === AuthSharedActionTypes.Logout) {
      state = undefined;
    }

    return reducer(state, action);
  };
}

const metaReducers = [clearState];

export function getMetaReducers(): MetaReducer<State<any>>[] {
  return metaReducers.concat([logrocketMiddleware]);
}

function BaseHrefFn(electronService: ElectronService): string {
  if (electronService.isElectron) {
    return './';
  } else {
    return '/';
  }
}

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    StoreModule.forRoot({ router: routerReducer }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: isDevMode() ? 100 : 25,
      logOnly: false,
      autoPause: true,
    }),
    RouterModule.forRoot(appRoutes, { enableTracing: false }),
    StoreRouterConnectingModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    NgxGoogleAnalyticsModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.FIREBASE_CONFIG),
    TuiRoot,
    TuiDialog,
    TuiAlert,
    ...TuiPreview,
    LetDirective,
    AuthSharedModule,
    AppStateModule,
    YardManagementModule,
    VendorPoliciesModule,
    SharedNotificationsModule,
    SharedServicesQueryParamsModule,
    PushNotificationsModule,
    SoftwareRegistrationModule,
    AboutModule,
    CartSharedModule,
    ConnectFrontendModule,
    TuiLink,
  ],
  schemas: [],
  providers: [
    provideAnimations(),
    NG_EVENT_PLUGINS,
    provideGoogleAnalytics(environment.GOOGLE_ID || ''),
    provideGoogleAnalyticsRouter(),
    {
      provide: USER_PROVIDED_META_REDUCERS,
      useFactory: getMetaReducers,
    },
    { provide: APP_BASE_HREF, useFactory: BaseHrefFn, deps: [ElectronService] },
    { provide: ENVIRONMENT, useValue: environment },
    {
      provide: TUI_NOTIFICATION_OPTIONS,
      useValue: {
        ...TUI_NOTIFICATION_DEFAULT_OPTIONS,
        autocloseTimeout: 7000,
        label: ({ $implicit }: any) =>
          $implicit === 'Error' ? 'Error' : 'Info',
        status: 'error',
        hasIcon: false,
        hasCloseButton: false,
      },
    },
    tuiIconResolverProvider((icon) => {
      return icon.includes('tui')
        ? `/assets/taiga-ui/icons/${icon.replace('@tui.', '')}.svg`
        : `/assets/icons/${icon}.svg`;
    }),
    { provide: IpcService, useFactory: IpcFactory, deps: [ElectronService] },
    {
      provide: TUI_NUMBER_FORMAT,
      useFactory: () =>
        inject(TUI_LANGUAGE).pipe(
          map(({ name }) => ({
            ...TUI_DEFAULT_NUMBER_FORMAT,
            thousandSeparator: name === 'english' ? ',' : ' ',
            decimalSeparator: name === 'english' ? '.' : ',',
          })),
        ),
    },
    LaunchDarklyService,
    CookieService,
    ElectronService,
    UsersService,
    ShortcutsUiService,
    LogrocketService,
  ],
})
export class AppModule extends BaseModule {
  override get routes() {
    return appRoutes;
  }
}
